import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { Link, useParams } from "react-router-dom";
import { getAllIes, listaDisciplinasMatriz } from "../../Service/Syllabus";
import { TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    maxWidth: '100%'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Home() {
  // Estados dos selects - lista de itens
  const [iesList, setIesList] = React.useState<Array<any>>([]);
  const [cursoList, setCursoList] = React.useState<Array<any>>([]);
  // const [matrizList, setMatrizList] = React.useState<Array<any>>([]);
  const [disciplinasList, setDisciplinasList] = React.useState<Array<any>>([]);
  const [disciplinasListCopie, setDisciplinasListCopie] = React.useState<Array<any>>([]);

  const getCursos = (codIes: number) => {
    const ies = iesList.find((obj) => obj?.value === codIes);
    let cursos = ies?.cursos.map((el: any) => { return { value: el.cod_curso, label: el.curso } })
    setCursoList(cursos)
  }

  // Estados do select - itens selecionados
  const [ies, setIes] = React.useState("");
  const [curso, setCurso] = React.useState("");

  const clearDisciplinaFilter = () => {
    const element: any = document.getElementById("buscar-disciplina")
    element.value = ""
  }

  const handleIes = (event: any) => {
    setIes(event.value as string);
    setCurso("")
    setDisciplinasList([])
    clearDisciplinaFilter()
    getCursos(event.value as number)
  };

  const handleCurso = (event: any) => {
    clearDisciplinaFilter()
    setCurso(event.value);
    listaDisciplinasMatriz(ies, event.value)
      .then((resp: any) => {
        setDisciplinasList(resp.disciplina_list)
        setDisciplinasListCopie(resp.disciplina_list)
      })
      .catch((error: any) => {
        console.log(error)
      });

  };

  function filterDisciplina(e: any) {
    if (e.keyCode === 27) {
      e.target.value = ""
    }
    if (e.target.value === "") {
      setDisciplinasList(disciplinasListCopie)
    } else {
      let disciplinasFiltadas = disciplinasListCopie.filter((value: any) => {
        return (value?.disciplina?.match(new RegExp(`${e.target.value}`, 'gi')))
      });
      setDisciplinasList([...disciplinasFiltadas]);
    }
  };


  const { inst } = useParams();
  React.useEffect(() => {
    getAllIes()
      .then((resp) => {
        let listaIes = resp.ies_curso_list.map((el: any) => { return { value: el.cod_ies, label: el.ies, cursos: el.cursos } })
        setIesList(listaIes)
        if (inst) {
          setIes(inst)
          setCurso("")
          const ies = resp.ies_curso_list.find((obj: any) => obj?.cod_ies == inst);
          let cursos = ies?.cursos.map((el: any) => { return { value: el.cod_curso, label: el.curso } })
          setCursoList(cursos)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        {'UNINTA '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}
      >
        <section style={{ display: "flex", flexWrap: "wrap", gap: "20px", zIndex: "9" }}>
          <FormControl style={{ flexGrow: 0.8, width: '350px', maxWidth: '100%' }}>
            <Select
              // id="selec-uninta-disciplinas"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#bd3e3e',
                },
              })}
              placeholder="Instituição"
              options={iesList}
              value={iesList[iesList.findIndex((el) => el.value == ies)]}
              onChange={handleIes}
              isDisabled={inst ? true : false}
            />
          </FormControl>
          <FormControl style={{ flexGrow: 0.8, width: '350px', maxWidth: '100%' }}>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#bd3e3e',
                },
              })}
              value={curso ? cursoList[cursoList.findIndex((el) => el.value == curso)] : -1}
              placeholder="Cursos"
              options={cursoList}
              onChange={handleCurso}
            />
          </FormControl>
          {/* <FormControl style={{ maxWidth: '400px', width: '350px' }}>
            <Select
              placeholder="Matrizes"
              options={matrizList}
              onChange={handleMatriz}
            />
          </FormControl> */}
        </section>
        <section style={{ display: "flex", gap: "20px", zIndex: "8" }}>
          <FormControl style={{ flexGrow: 0.8, width: '350px', maxWidth: '600px' }}>
            <TextField
              style={{
                fontSize: "30px"
              }}
              id="buscar-disciplina"
              label="Buscar Disciplina"
              variant="outlined"
              onKeyUp={(e) => { filterDisciplina(e) }}
              InputProps={{
                endAdornment: <SearchIcon style={{ color: '#bd2e2e' }} />
              }}
            />
          </FormControl>
        </section>
        <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
          <Table stickyHeader sx={{ overflow: "scroll" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Código</StyledTableCell>
                <StyledTableCell align="left">Nome da Disciplina</StyledTableCell>
                <StyledTableCell align="left">Matriz</StyledTableCell>
                <StyledTableCell align="left">Plano de Ensino</StyledTableCell>
                <StyledTableCell align="left">Ações</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                disciplinasList?.map((disciplina: any, index: number) => {
                  let disciplinaData = JSON.stringify({
                    ies,
                    curso,
                    curso_nome: cursoList[cursoList.findIndex((el) => el.value == curso)].label,
                    matriz: disciplina.cod_matriz,
                    disciplina: disciplina.cod_disc.replace('$slash$', '/'),
                    disciplina_nome: disciplina.disciplina,
                    plano: disciplina.id
                  });

                  disciplinaData = disciplinaData.replace(/[^\x00-\xFF]/g, '');

                  const encodedData = encodeURIComponent(btoa(disciplinaData));

                  return <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {disciplina.cod_disc.replace('$slash$', '/')}
                    </StyledTableCell>
                    <StyledTableCell align="left"><Link target="_blank" to={`/plano-ensino?plano=${encodedData}`}>{disciplina.disciplina}</Link></StyledTableCell>
                    <StyledTableCell align="left">{disciplina.cod_matriz}</StyledTableCell>
                    <StyledTableCell align="left">
                      <Link target="_blank" to={`/plano-ensino?plano=${encodedData}`}>Plano de Ensino</Link>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link target="_blank" to={`/plano-aula/${encodedData}`}>Gerar Plano de Aula</Link>
                    </StyledTableCell>
                  </StyledTableRow>
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <footer
        style={{
          justifyContent: "center"
        }}
      >
        <Copyright sx={{ mb: "5px" }} />
      </footer>
    </div>
  );
}

export default Home;
