import React from "react";
import Home from "./Pages/home";
import Plano from "./Pages/plano";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import PlanoAulaForm from "./Pages/planoAulaForm";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { PrimeReactProvider } from "primereact/api";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#bd2e2e",
      },
      secondary: {
        main: "#54595F",
      },
    },
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              color: "#999999", // Cor do ícone do MuiStepIcon quando ele está 'active'
            },
            "&.Mui-completed": {
              color: "#007DFF", // Cor do ícone do MuiStepIcon quando ele está 'completed'
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiFilledInput-root.Mui-focused": {
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
            },
            "& .MuiFilledInput-root": {
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#bd2e2e", // Cor da borda quando o TextField estiver focado.
              },
            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#bd2e2e", // Cor da borda quando o TextField estiver em erro.
              },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "12px", // Personalize o padding conforme necessário
            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "#54595F", // Personalize a cor de fundo conforme necessário
          },
          arrow: {
            color: "#54595F",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "#B8DBD9",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-head": {
              fontSize: "18px",
              backgroundColor: "#bd3e3e",
            },
          },
        },
      },
    },
  });

  const Public = createBrowserRouter([
    {
      path: "/:inst",
      element: <Home />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "plano-ensino",
      Component: Plano,
    },
    {
      path: "*",
      element: <>404</>,
    },
    {
      path: "plano-aula/:params",
      element: <PlanoAulaForm />,
    }
  ]);

  return (
    <React.Fragment>
      <main>
        <PrimeReactProvider>
          <ThemeProvider theme={theme}>
            <RouterProvider router={Public} />
          </ThemeProvider>
        </PrimeReactProvider>
      </main>
    </React.Fragment>
  );
}

export default App;
