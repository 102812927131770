import * as React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import Syllabus from './components/syllabus'
import "./style.css"

function Plano() {
  return  <PDFViewer style={{ width: "100vw", height: "100vh"}}>
            <Syllabus/>
          </PDFViewer>
}

export default Plano;