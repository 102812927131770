import * as React from "react";
import { Page, Text, Image, View, Document } from '@react-pdf/renderer';
import { getPlanoEnsino } from "../../../Service/Syllabus";

function adjustHtmlString(text: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html')
    let htmlContent: any = ""
    let childreNodes = doc.body.children
    Array.from(childreNodes).map((element: any) => {
        htmlContent += `\n${element.outerHTML}`
    });
    return new DOMParser().parseFromString(htmlContent, 'text/html').body.textContent
}

function Syllabus () {
    const [syllabus, setSyllabus] = React.useState <any> (undefined);
    
    React.useEffect(() => {
        const queryParam = window.location.search.substring(1);
        const params = new URLSearchParams(queryParam);
        const urlParam: any = params.get("plano")
        const parametros = JSON.parse(atob(decodeURIComponent(urlParam)))
  
        getPlanoEnsino(parametros.ies, parametros.curso, parametros.matriz, parametros.disciplina, parametros.plano)
            .then((resp: any) => {
                setSyllabus(resp.syllabus[0])
            })
            .catch((error: any) => {
                console.log(error)
            })
    }, [])
  
    return syllabus !== undefined ?
        <Document>
            <Page size="A4" style={{
              padding: 40,
              fontSize: 12
            }}>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: 'left', 
                  }}
                  >
                    <View style={{
                      textAlign: 'left',
                      flexBasis: "30%",
                      margin: 10,
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000",
                      borderRadius: 20
                    }}
                    >
                      <Image
                        style={{
                          maxHeight: 100,
                          marginVertical: 10
                        }}
                        src="logo-uninta.png"
                      />
                    </View>
                    <View style={{
                      textAlign: 'center',
                      flexBasis: "70%",
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000",
                      borderRadius: 20
                    }}
                    >
                      <Text>
                        Plano de ensino
                      </Text>
                      <Text
                        style={{
                          fontSize: 15,
                          padding: 5
                        }}
                      >
                        Curso de Lic./Bachar./Tecnol. em {syllabus?.curso}
                      </Text>
                      <Text
                        style={{
                          fontSize: 15
                        }}
                      >
                        Modalidade: {syllabus?.modalidade}
                      </Text>
                    </View>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    borderStyle: 'solid',
                    marginTop: 20,
                    borderWidth: 2,
                    padding: 10,
                    borderColor: "#000000",
                    borderRadius: 20
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    Disciplina: {syllabus?.disciplina}
                  </Text>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    borderStyle: 'solid',
                    marginTop: 20,
                    borderWidth: 2,
                    padding: 10,
                    borderColor: "#000000",
                    borderRadius: 20
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    Docente Responsável: {syllabus?.docente}
                  </Text>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    marginTop: 20,
                    borderStyle: 'solid',
                    borderWidth: 2,
                    padding: 10,
                    borderColor: "#000000",
                    borderRadius: 20
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    Coordenador: {syllabus?.coordenador}
                  </Text>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    padding: 10,
                    marginTop: 20,
                    borderColor: "#000000"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    1. UNIDADE TEMÁTICA
                  </Text>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    padding: 10,
                    borderColor: "#000000"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    1.1 Ementa
                  </Text>
                </View>
                <View
                 style={{
                    textAlign: 'left',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    padding: 20,
                    borderColor: "#000000"
                  }}
                >
                  <Text>
                    {adjustHtmlString(syllabus?.ementa)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    1.2 Objetivos
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.objetivos)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    1.3 Competências
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.competencias)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    1.4 Habilidades
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.habilidades)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      marginTop: 20,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    2. CONTEÚDO PROGRAMÁTICO
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.conteudo_programatico)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      marginTop: 20,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    3. METODOLOGIAS
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.metodologias)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      marginTop: 20,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    4. SISTEMA DE AVALIAÇÃO
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left",
                      width: '95%'
                    }}
                  >
                    {adjustHtmlString(syllabus?.sistema_avaliacao)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      marginTop: 20,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    5. BIBLIOGRAFIAS
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    5.1 BIBLIOGRAFIA BÁSICA
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.bibliografia_basica)}
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 10,
                      borderColor: "#000000"
                    }}
                  >
                  <Text
                    style={{
                      textAlign: "left"
                    }}
                  >
                    5.2 BIBLIOGRAFIA COMPLEMENTAR
                  </Text>
                </View>
                <View
                  style={{
                      textAlign: 'left',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      padding: 20,
                      borderColor: "#000000"
                    }}
                  >
                  <Text>
                    {adjustHtmlString(syllabus?.bibliografia_complementar)}
                  </Text>
                </View>
              </Page>
        </Document>
        : <></>
    ;
  };

  export default Syllabus;