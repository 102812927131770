import axios from "axios";

const baseURL = 'https://p85m6g74ag.execute-api.us-east-1.amazonaws.com';

const getAllIes = async () => {
  const response = await axios.get(`${baseURL}/ies`);
  return response.data;
};

async function listaDisciplinasMatriz(codIes: string | null, codCurso: string | null) {
  const response = await axios.get(`${baseURL}/ies/${codIes}/cursos/${codCurso}/matrizes`);
  return response.data;
}

async function getPlanoEnsino(codIes: string | null, codCurso: string | null, codMatriz: string | null, codDisc: string | null, idPlano: string | null) {
  const response = await axios.get(`${baseURL}/ies/${codIes}/cursos/${codCurso}/matrizes/${codMatriz}/disciplinas/${codDisc}/planos/${idPlano}`);
  return response.data;
}

export { getAllIes, listaDisciplinasMatriz, getPlanoEnsino };