import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, useTheme } from "@mui/material";
import { Toast } from "primereact/toast";
import { postAutenticacao } from "../../../Service/LLMGetway";
import { ProgressBar } from "primereact/progressbar";
import "./style.css";

export default function DialogAutenticacao({ open, setOpen }: any) {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);

    const toast = React.useRef<Toast>(null);

    const [formData, setFormData] = React.useState({
        username: "",
        password: "",
    });

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const closeDialog = () => {
        setFormData({
            username: "",
            password: "",
        });
        setOpen(false);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        postAutenticacao(formData)
            .then((resp: any) => {
                let stringEncoded = window.btoa(JSON.stringify(resp));
                sessionStorage.setItem("@access", stringEncoded);
                toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Autenticação efetuada!', life: 3000 });
                closeDialog();
            })
            .catch((error: any) => {
                console.log(error);
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Falha na autenticação!', life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            fullWidth
            open={open}
            disableEscapeKeyDown={true}
            style={{ padding: "30px" }}
        >
            {
                loading ?
                    <ProgressBar mode="indeterminate" color={theme.palette.primary.main} style={{ height: '6px' }}></ProgressBar>
                    :
                    <></>
            }

            <Toast ref={toast} />

            <DialogTitle align="center">Autenticação</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <TextField
                        focused
                        name="username"
                        margin="normal"
                        label="Usuário*:"
                        type="email"
                        value={formData.username}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        focused
                        name="password"
                        margin="normal"
                        label="Senha*:"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                {
                    loading ?
                        <div className='botao-loading-autenticacao' style={{ backgroundColor: theme.palette.primary.main }}>
                            <div className="dot-pulse">
                                <div className="dot-pulse__dot"></div>
                            </div>
                        </div>
                        :
                        <Button
                            variant="outlined"
                            type="submit"
                            style={{ color: "white", backgroundColor: theme.palette.primary.main }}
                            onClick={(e: any) => { setLoading(true); handleSubmit(e); }}
                        >
                            Entrar
                        </Button>
                }
            </DialogActions>
        </Dialog>
    )
}