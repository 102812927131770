import { llm_getway_api } from '.';
import { DataLog, DataRenovarAcesso, FormDataPlanoAula, FormLogin } from './types';

async function postPlanoAula(formData: FormDataPlanoAula) {
  const response = await llm_getway_api.post(`/plano-aula/`, formData);
  return response.data;
}

async function postAutenticacao(formData: FormLogin) {
  const response = await llm_getway_api.post(`/autenticacao/`, formData);
  return response.data;
}

async function postRenovarAcesso(data: DataRenovarAcesso) {
  const response = await llm_getway_api.post(`/autenticacao/renovar`, data);
  return response.data;
}

async function postLog(data: DataLog) {
  const response = await llm_getway_api.post(`/plano-aula/log`, data);
  return response.data;
}

export { postPlanoAula, postAutenticacao, postRenovarAcesso, postLog };