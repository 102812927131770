import * as React from "react";
import { Button, Divider, Typography, Card, useTheme } from "@mui/material";
import { Check, AssignmentRounded } from "@mui/icons-material";
import "./style.css";

export default function PlanoAulaGerado({ planoAulaGerado, canSaveDocument, setCanSaveDocument }: any) {
  const theme = useTheme();
  const [textoCopiado, setTextoCopiado] = React.useState(false);
  // const { params } = useParams();
  // const parametros = params ? JSON.parse(atob(decodeURIComponent(params))) : '';

  React.useEffect(() => {
    if (planoAulaGerado == null) {
      setCanSaveDocument(false);
    } else {
      setCanSaveDocument(true);
    }
  }, [planoAulaGerado, setCanSaveDocument]);

  const handleCopiarConteudoRespostaIA = () => {
    navigator.clipboard.writeText(planoAulaGerado);

    setTextoCopiado(true);

    setTimeout(() => {
      setTextoCopiado(false);
    }, 1000);
  }

  // function parseHtmlStringToReactElements(htmlString: any) {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, 'text/html');
  //   const elements = doc.body.children;

  //   // Função para adicionar estilo quando a classe for 'ql-align-center'
  //   const applyStyles = (element: any) => {
  //     if (element.classList.contains('ql-align-center')) {
  //       element.style.textAlign = 'center';
  //     }
  //     if (element.classList.contains('ql-align-right')) {
  //       element.style.textAlign = 'right';
  //     }
  //     if (element.classList.contains('ql-align-left')) {
  //       element.style.textAlign = 'left';
  //     }
  //     if (element.classList.contains('ql-align-justify')) {
  //       element.style.textAlign = 'justify';
  //     }
  //     if (element.tagName === "UL") {       
  //       element.style.listStyle = 'disc';
  //     }
  //   };

  //   // Transforma os elementos em elementos React
  //   const reactElements = Array.from(elements).map((element, index) => {
  //     applyStyles(element);

  //     // Use key para evitar warning de elementos irmãos sem uma chave única
  //     return (
  //       <div key={index} dangerouslySetInnerHTML={{ __html: element.outerHTML }} />
  //     );
  //   });

  //   return reactElements;
  // }

  // function transformHTMLString(objeto: any) {
  //   let resultado = "";

  //   for (let i = 0; i < objeto.length; i++) {
  //     const props = objeto[i].props;
  //     if (props && props.dangerouslySetInnerHTML && props.dangerouslySetInnerHTML.__html) {
  //       resultado += props.dangerouslySetInnerHTML.__html;
  //     }
  //   }

  //   return resultado;
  // }

  // const handleCreatePDF = () => {
  //   const doc = new jsPDF();
  //   const parsedElements = parseHtmlStringToReactElements(planoAulaGerado);
  //   const htmlStringFromReact = transformHTMLString(parsedElements);

  //   doc.html(htmlStringFromReact, {
  //     callback: function (doc) {
  //       doc.save("plano-aula.pdf");
  //     },
  //     margin: [10, 10, 10, 10],
  //     autoPaging: "text",
  //     x: 0,
  //     y: 0,
  //     width: 190,
  //     windowWidth: 675
  //   });

  //   postLog({
  //     cod_curso: parametros.curso,
  //     cod_disciplina: parametros.disciplina,
  //     metodologia_ativa: formData.metodologia_ativa,
  //     modalidade: formData.modalidade,
  //     action: "Gerar PDF",
  //   })
  //   .catch((error: any) => {
  //     console.log(error);
  //   })
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px"
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "5px" }}
      >
        <Typography variant='h5' style={{ color: "#525252" }}>
          Olá
        </Typography>
        <Typography variant='h5' id="nome-professor" style={{ color: theme.palette.primary.main }}>
          Professor
        </Typography>
        <Typography variant='h5' style={{ color: "#525252" }}>
          Seja bem-vindo(a) ao Gerador de Planos de Aula IA
        </Typography>
        <Typography variant='subtitle1' style={{ textAlign: "center" }} >
          Este espaço é destinado à criação generativa de Planos de Aula Personalizados.
        </Typography>
      </div>

      <Divider style={{ width: "100%" }} />

      <p style={{ textAlign: "justify", color: "#000000DE" }}>
        A interação entre o professor, os parâmetros do formulário, a inteligência artificial (IA) e o resultado final se combinam para produzir planos de aula personalizados e eficazes. A IA desempenha um papel central na geração inicial, enquanto o professor mantém o controle e a capacidade de personalização, assegurando que o plano atenda às necessidades específicas da disciplina e dos alunos.
      </p>

      {
        canSaveDocument ?
          <>
            <h4 style={{ textAlign: "center" }}>Plano de Aula Gerado</h4>

            <Card className="card-resposta-ia" elevation={3}>
              <div className="container-resposta-ia">
                <pre>
                  {planoAulaGerado}
                </pre>
              </div>

              <div className="botoes-resposta-ia">
                <Button
                  variant="text"
                  style={{
                    fontWeight: "bold",
                    color: !canSaveDocument ? "#b65a5a" : theme.palette.primary.main
                  }}
                  onClick={() => { handleCopiarConteudoRespostaIA(); }}
                >
                  {textoCopiado ? "Copiado" : "Copiar"}
                </Button>
              </div>
            </Card>
          </>
          :
          <></>
      }
    </div>
  );
}
