import axios from 'axios';

export const llm_getway_api = axios.create({
  // baseURL: "https://quiet-true-gnu.ngrok-free.app"
  baseURL: "https://api-llm-gateway.aiamis.com.br"
});

/**
 * Setando o [token], [ra_aluno] e [type_user]
 * globalmente no header das requisições
 */
llm_getway_api.interceptors.request.use(config => {
  let data: any = sessionStorage.getItem('@access');

  if (data) {
    data = JSON.parse(window.atob(data));
  }

  if (!!data && config.headers) {
    config.headers['Authorization'] = `Bearer ${data.access_token}`;
  }

  return config;
})