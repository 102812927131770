import * as React from "react";
import TextField from "@mui/material/TextField";
import { AutoFixHigh } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, MenuItem, useTheme } from "@mui/material";
import "./style.css";

export default function FormPlanoAula({ parametros, formData, handleSubmit, setFormData }: any) {
  const theme = useTheme();
  const [todosCamposVazios, setTodosCamposVazios] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const todosCamposVazios = !Object.keys(formData).every((campo) => {
      if (campo === 'recursos_disponiveis') {
        return true; // Pula o campo 'recursos_disponiveis'
      }
      return formData[campo] !== '';
    });
    setTodosCamposVazios(todosCamposVazios);
  }, [formData]);

  React.useEffect(() => {
    if (parametros !== '') {
      setFormData((prevData: any) => ({
        ...prevData,
        nome_curso: parametros.curso_nome,
        disciplina: parametros.disciplina_nome
      }));
    }
  }, [setFormData, parametros]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5", padding: "30px" }}>
      <h1 style={{ fontSize: "15px", textAlign: "center", marginBottom: "30px" }}>
        FORMULÁRIO
      </h1>

      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
        onSubmit={(e) => { setLoading(true); handleSubmit(e, formData, setLoading); }}
      >
        <FormControl>
          <TextField
            fullWidth
            disabled
            name="nome_curso"
            value={formData.nome_curso}
            id="outlined-basic"
            label="Curso*"
            variant="outlined"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            disabled
            name="disciplina"
            value={formData.disciplina}
            id="outlined-basic"
            label="Disciplina*"
            variant="outlined"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="assunto"
            value={formData.assunto}
            onChange={handleChange}
            id="outlined-basic"
            label="Assuntos Abordados*"
            placeholder="Descreva os tópicos que serão abordados no seu plano de aula."
            variant="outlined"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            name="metodologia_ativa"
            value={formData.metodologia_ativa}
            onChange={handleChange}
            id="outlined-basic"
            label="Metodologia Ativa*"
            variant="outlined"
            select
          >
            <MenuItem value=""><em>Selecione uma Metodologia Ativa</em></MenuItem>
            <MenuItem value="Aprendizagem Baseada em Projetos">Aprendizagem Baseada em Projetos</MenuItem>
            <MenuItem value="Sala de Aula Invertida">Sala de Aula Invertida</MenuItem>
            <MenuItem value="Aprendizagem Cooperativa">Aprendizagem Cooperativa</MenuItem>
            <MenuItem value="Aprendizagem Significativa">Aprendizagem Significativa</MenuItem>
          </TextField>
        </FormControl>

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Modalidade*
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="modalidade"
            value={formData.modalidade}
            onChange={handleChange}
          >
            <FormControlLabel value="Presencial" control={<Radio />} label="Presencial" />
            <FormControlLabel value="Ead" control={<Radio />} label="EAD" />
            <FormControlLabel value="Hibrido" control={<Radio />} label="Híbrido" />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="recursos_disponiveis"
            onChange={handleChange}
            placeholder="Liste os recursos disponíveis para esta aula."
            id="outlined-basic"
            label="Recursos Disponíveis"
            variant="outlined"
          />
        </FormControl>

        {
          loading ?
            <div className='botao-loading' style={{ backgroundColor: theme.palette.primary.main }}>
              <div className="dot-pulse">
                <div className="dot-pulse__dot"></div>
              </div>
            </div>
            :
            <Button
              type="submit"
              variant="contained"
              disabled={todosCamposVazios}
              endIcon={<AutoFixHigh />}
              style={{ backgroundColor: todosCamposVazios ? "#b65a5a" : theme.palette.primary.main, color: "white" }}
            >
              Gerar Plano de Aula
            </Button>
        }
      </form>
    </div>
  );
}
