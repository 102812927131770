import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import FormPlanoAula from "../../Components/formPlanoAula";
import PlanoAulaGerado from "../../Components/planoAulaGerado";
import { postLog, postPlanoAula, postRenovarAcesso } from "../../Service/LLMGetway";
import DialogAutenticacao from "../../Components/dialogs/autenticacao";
import { FormDataPlanoAula } from "../../Service/types";

export default function Form() {
  const { params } = useParams();
  const [open, setOpen] = React.useState<boolean>(false);
  const [canSaveDocument, setCanSaveDocument] = React.useState<boolean>(false);
  const [planoAulaGerado, setPlanoAulaGerado] = React.useState<string | null>(null);
  const parametros = params ? JSON.parse(atob(decodeURIComponent(params))) : '';
  const [formData, setFormData] = React.useState<FormDataPlanoAula>({
    nome_curso: "",
    disciplina: "",
    assunto: "",
    metodologia_ativa: "",
    modalidade: "",
    recursos_disponiveis: ""
  });

  const verificarStatusError = (error: any, formData: FormDataPlanoAula, setLoading: any) => {
    if (error?.response?.status === 401) {
      setOpen(true);

      if (formData.recursos_disponiveis == "") {
        delete formData["recursos_disponiveis"];
      }

      postPlanoAula(formData)
        .then((resp: any) => {
          setPlanoAulaGerado(resp);
          setCanSaveDocument(true);
        })
        .catch((error: any) => {
          console.log(error);
          setOpen(true);
        })
    }

    if (error?.response?.status === 403) {
      const refreshToken = JSON.parse(window.atob(sessionStorage.getItem("@access") as string)).refresh_token;

      setLoading(true);

      postRenovarAcesso({
        grant_type: "refresh_token",
        refresh_token: refreshToken
      })
        .then((resp: any) => {
          let stringEncoded = window.btoa(JSON.stringify(resp)); // Criptografa a resposta em base 64
          sessionStorage.setItem("@access", stringEncoded);

          postPlanoAula(formData)
            .then((resp: any) => {
              setPlanoAulaGerado(resp);
              setCanSaveDocument(true);
            })
            .catch((error: any) => {
              console.log(error);
              setOpen(true);
            })
        })
        .catch((error: any) => {
          console.log(error);
          setOpen(true);
        })
    }
  }

  const handleSubmit = async (event: any, formData: FormDataPlanoAula, setLoading: any) => {
    event.preventDefault();

    if (formData.recursos_disponiveis == "") {
      delete formData["recursos_disponiveis"];
    }

    postPlanoAula(formData)
      .then((resp: any) => {
        setPlanoAulaGerado(resp);
        // setPlanoAulaGerado(resp?.choices[0]?.message?.content);
        setCanSaveDocument(true);

        postLog({
          cod_curso: parametros.curso,
          cod_disciplina: parametros.disciplina,
          metodologia_ativa: formData.metodologia_ativa,
          modalidade: formData.modalidade,
          action: "Gerar Plano de Aula",
        })
          .catch((error: any) => {
            console.log(error);
          })
      })
      .catch((error: any) => {
        console.log(error);
        setCanSaveDocument(false);

        verificarStatusError(error, formData, setLoading);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (!sessionStorage.getItem("@access")) {
      setOpen(true);
    }
  }, [setOpen]);

  return (
    <div
      style={{
        width: "100%",
        padding: "30px"
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <FormPlanoAula
            parametros={parametros}
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={9}>
          <PlanoAulaGerado
            planoAulaGerado={planoAulaGerado}
            canSaveDocument={canSaveDocument}
            setCanSaveDocument={setCanSaveDocument}
          />
        </Grid>
      </Grid>

      <DialogAutenticacao
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}
